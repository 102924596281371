import React from 'react';

import { Layout } from '../components/layouts';
import { SEO } from '../components/common';
import { PaymentSection } from '../components/sections';

const PaymentPage = (): JSX.Element => {
  return (
    <Layout enablePage hideHeader={true} hideFooter={true}>
      <SEO title="Make Payment" />
      <PaymentSection />
    </Layout>
  );
};

export default PaymentPage;
